import logo from './logo.svg';
import './App.css';
import Header from './components/common/Header'
import Soon from './components/common/Soon';
function App() {
  return (
    <>
    {/* <Header/> */}
    <Soon/>
    </>
  );
}

export default App;
