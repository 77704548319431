import { motion } from 'framer-motion';
import React from 'react'
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaLocationArrow, FaPhoneVolume } from 'react-icons/fa'
import { IoShareSocial } from 'react-icons/io5'

export default function Soon() {
    const letters = ['w', 'e', ' ','w', 'i', 'l', 'l', ' ','b','e', ' ','b','a','c','k',' ','s','o','o','n','!!'];
  return (
    <div className='w-[100vw] bg-[#FFFDD0] h-[100vh] flex justify-center items-center relative'>
        <img src={require('../../assets/images/full logo.png')} alt="" className='h-[4rem] absolute top-[1rem] left-3' />
        <div className='max-w-[1400px] flex w-[100%] h-[100%] items-center'>
            <div className='md:text-[3.5rem] flex-wrap text-[#781E41]  max-md:text-[3.5rem] max-md:leading-[4rem] max-md:text-center w-[100%] leading-[4rem] flex gap-10 justify-center items-center  font-extrabold tracking-wider lowercase'>
                <h1 style={{fontFamily:'"Heebo", sans-serif'}}>
                {letters.map((letter, index) => (
        <motion.span
          key={index}
          initial={{ x: -200, opacity: 0 }} // initial position and opacity
          animate={{ x: 0, opacity: 1 }} // final position and opacity
          transition={{
            duration: 1,
            ease: 'easeInOut',
            delay: index * 0.1, // stagger the animation
          }}
        
        >
          {letter}
        </motion.span>
      ))}
                </h1>
      
                {/* <div  className='border-l-[5px]  border-black h-[20rem] '/> */}
                {/* <div className='flex flex-col gap-3'>
                    <div>
                        <h1 className='text-black text-[2.5rem] leading-[2.5rem] tracking-wider uppercase'>
                            We will be back soon
                            <p className='text-[1rem] font-normal italic text-black tracking-wider capitalize'>in the mean time connect with uswith information below.</p>
                        </h1>
                        <div className='text-black flex items-center mt-[2rem] gap-5'>
                        <FaPhoneVolume />
                            <div className='text-[1rem] tracking-wider font-semibold flex flex-col gap-1 items-center'>
                                <h1 className='leading-[1.2rem]'>+91 89717 82510</h1>
                                <h1 className='leading-[1.2rem]'>+91 86602 93500</h1>
                            </div>
                        </div>
                        <div className='text-black flex items-center mt-[2rem] gap-10'>
                        <FaLocationArrow className='text-[2.2rem]' />
                            <div className='text-[1rem] tracking-wider font-semibold flex flex-col gap-1 items-center'>
                                <h1 className='leading-[1.2rem] w-[20rem] items-start'>Neelgiri Garden, Keshwapur, Kusugal Rd, behind Royal Enfield Showroom, Hubballi, Karnataka 580023.</h1>
                                
                            </div>
                        </div>
                        <div className='text-black flex items-center mt-[2rem] gap-8'>
                        <IoShareSocial className='text-[3rem]' />
                            <div className='text-[2rem] tracking-wider font-semibold flex flex-col gap-1 items-center'>
                                <h1 className='leading-[1.2rem] flex gap-2'><FaFacebook /> <FaInstagramSquare /> <FaLinkedin /></h1>
                               
                            </div>
                        </div>
                    </div>

                </div> */}
                
            </div>


        </div>
      
    </div>
  )
}
